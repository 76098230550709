export default class BloqueioMatricula {
  constructor(
    id = null,
    dataBloqueio = null,
    motivoBloqueio = null,
    dataDesbloqueio = null,
    motivoDesbloqueio = null,
    matricula = null,
  ) {
    this.id = id
    this.dataBloqueio = dataBloqueio
    this.motivoBloqueio = motivoBloqueio
    this.dataDesbloqueio = dataDesbloqueio
    this.motivoDesbloqueio = motivoDesbloqueio
    this.matricula = matricula
  }
}
