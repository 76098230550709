export default class BloqueioMatriculaService {
  constructor(http) {
    this._http = http
  }

  async getListaBloqueioMatriculasById(matriculaId) {
    const { data } = await this._http.get(
      `/api/bloqueio_matricula/matricula/${matriculaId}`,
    )
    return data
  }

  async saveBloqueioMatricula(bloqueioMatricula) {
    if (bloqueioMatricula.id) {
      return await this._http.put('/api/bloqueio_matricula', bloqueioMatricula)
    } else {
      return await this._http.post('/api/bloqueio_matricula', bloqueioMatricula)
    }
  }
}
