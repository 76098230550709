<template>
  <div>
    <div>
      <Card class="col-12">
        <template #content>
          <div class="p-fluid formgrid grid d-flex mt-5">
            <div class="col-12 md:col-3">
              <label for="margem6" class="font-bold uppercase">{{
                labelMargem('margem6')
              }}</label>

              <InputNumber
                id="margem6"
                v-model="store.dados.margem6"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem5" class="font-bold uppercase">{{
                labelMargem('margem5')
              }}</label>
              <InputNumber
                id="margem5"
                v-model="store.dados.margem5"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem4" class="font-bold uppercase">{{
                labelMargem('margem4')
              }}</label>
              <InputNumber
                id="margem4"
                v-model="store.dados.margem4"
                mode="currency"
                currency="BRL"
                disabled />
            </div>

            <div class="field col-12 md:col-3">
              <label for="margem3" class="font-bold uppercase">{{
                labelMargem('margem3')
              }}</label>
              <InputNumber
                id="margem3"
                v-model="store.dados.margem3"
                mode="currency"
                currency="BRL"
                disabled />
            </div>
          </div>

          <Panel header="Histórico de Bloqueios">
            <template #icons>
              <Button
                v-if="!store.dados.bloqueada"
                label="Bloquear Matrícula"
                icon="pi pi-times"
                class="p-button-sm p-button-danger"
                @click="abrirNovoBloqueio" />
              <Button
                v-if="store.dados.bloqueada"
                label="Desbloquear Matrícula"
                icon="pi pi-times"
                class="p-button-sm p-button-success"
                @click="abrirDesbloqueio" />
            </template>

            <DataTable
              :value="historicoBloqueios"
              :paginator="true"
              :rows="5"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} bloqueios de matrícula">
              <Column header="Bloqueado Em">
                <template #body="{ data }">
                  {{ data.dataBloqueio | formatarData }}
                </template>
              </Column>

              <Column header="Bloqueado Por">
                <template #body="{ data }">
                  {{ data.bloqueadoPor ? data.bloqueadoPor : ' - ' }}
                </template>
              </Column>

              <Column field="motivoBloqueio" header="Motivo do Bloqueio" />

              <Column header="Desbloqueado Em">
                <template #body="{ data }">
                  {{ data.dataDesbloqueio | formatarData }}
                </template>
              </Column>

              <Column field="desbl" header="Desbloqueado Por">
                <template #body="{ data }">
                  {{ data.desbloqueadoPor ? data.desbloqueadoPor : ' - ' }}
                </template>
              </Column>

              <Column header="Motivo do Desbloqueio">
                <template #body="{ data }">
                  {{ data.motivoDesbloqueio ? data.motivoDesbloqueio : ' - ' }}
                </template>
              </Column>
            </DataTable>
          </Panel>
        </template>
      </Card>

      <Dialog
        :visible.sync="bloqueioDialog"
        header="Bloquear Matrícula"
        :modal="true"
        class="p-fluid">
        <div class="inline-block col-12 md:col-12">
          <label for="motivo" class="required">Motivo</label>
          <Textarea
            id="motivo"
            v-model="motivo"
            :autoResize="true"
            :class="{
              'p-invalid': enviado && v$.motivo.$invalid,
            }" />
          <div v-if="enviado && v$.motivo.required.$invalid" class="p-error">
            O campo motivo é obrigatório
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text"
            @click="fecharNovoBloqueio" />
          <Button
            label="Salvar"
            icon="pi pi-check"
            class="p-button-text"
            @click="salvarBloqueio" />
        </template>
      </Dialog>
      <Dialog
        :visible.sync="desbloqueioDialog"
        header="Desbloquear Matrícula"
        :modal="true"
        class="p-fluid">
        <div class="inline-block col-12 md:col-12">
          <label for="motivo" class="required">Motivo</label>
          <Textarea
            id="motivo"
            v-model="motivo"
            :autoResize="true"
            :class="{
              'p-invalid': enviado && v$.motivo.$invalid,
            }" />
          <div v-if="enviado && v$.motivo.required.$invalid" class="p-error">
            O campo motivo é obrigatório
          </div>
        </div>
        <template #footer>
          <Button
            label="Não"
            icon="pi pi-times"
            class="p-button-text"
            @click="fecharDesbloqueio" />
          <Button
            label="Sim"
            icon="pi pi-check"
            class="p-button-text"
            @click="salvarDesbloqueio" />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { bus } from '@/main'
import { servidorStore } from '@/stores/servidor'
import BloqueioMatricula from '@/domain/BloqueioMatricula.js'
import Parametro from '@/domain/Parametro'
import BloqueioMatriculaService from '@/service/BloqueioMatriculaService.js'
import ParametroService from '@/service/ParametroService.js'

export default {
  setup() {
    const store = servidorStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      bloqueioMatricula: new BloqueioMatricula(),
      enviado: false,
      bloqueioDialog: false,
      desbloqueioDialog: false,
      historicoBloqueios: [],
      idMatricula: this.$route.params.id,
      parametro: new Parametro(),
      motivo: null,
    }
  },

  validations() {
    return {
      motivo: {
        required,
      },
    }
  },

  created() {
    this.bloqueioMatriculaService = new BloqueioMatriculaService(this.$http)
    this.parametroService = new ParametroService(this.$http)
  },

  mounted() {
    this.carregaBloqueioMatriculas()
    this.carregarParametros()
  },

  methods: {
    labelMargem(chave) {
      let label = this.store.dados.configuracoes?.find(
        (el) => el.chave === chave,
      )

      if (label) {
        if (label?.chave === 'margem6') {
          if (this.parametro?.porcentagemMargem6) {
            return `Empréstimo  ${this.parametro.porcentagemMargem6}%`
          }
        } else if (label?.chave === 'margem5') {
          if (this.parametro?.porcentagemMargem5) {
            return `Adiantamento  ${this.parametro.porcentagemMargem5}%`
          }
        } else if (label?.chave === 'margem4') {
          if (this.parametro?.porcentagemMargem4) {
            return `Cartão  ${this.parametro.porcentagemMargem4}%`
          }
        } else if (label?.chave === 'margem3') {
          if (this.parametro?.porcentagemMargem3) {
            return `Bens Duráveis  ${this.parametro.porcentagemMargem3}%`
          }
        }
        return label?.valor
      }
      return chave
    },

    carregarParametros() {
      this.parametroService
        .getOneByMatriculaId(this.idMatricula)
        .then((res) => {
          this.parametro = res
        })
    },

    abrirNovoBloqueio() {
      this.bloqueioMatricula = new BloqueioMatricula()
      this.bloqueioDialog = true
    },

    abrirDesbloqueio() {
      this.bloqueioMatricula = this.historicoBloqueios.find(
        (bloqueioMatricula) => bloqueioMatricula.dataDesbloqueio == null,
      )
      this.desbloqueioDialog = true
    },

    carregaBloqueioMatriculas() {
      this.bloqueioMatriculaService
        .getListaBloqueioMatriculasById(this.$route.params.id)
        .then((res) => {
          this.historicoBloqueios = res
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro. Tente novamente!',
            life: 10000,
          })
        })
    },

    fecharNovoBloqueio() {
      this.bloqueioDialog = false
      this.enviado = false
    },

    fecharDesbloqueio() {
      this.desbloqueioDialog = false
      this.enviado = false
    },

    salvarBloqueio() {
      if (this.validate()) {
        this.bloqueioMatricula.motivoBloqueio = this.motivo
        this.salvar(false, true, 'Bloqueio')
        this.bloqueioDialog = false
      }
    },

    salvarDesbloqueio() {
      if (this.validate()) {
        this.bloqueioMatricula.motivoDesbloqueio = this.motivo
        this.salvar(true, false, 'Desbloqueio')
        this.desbloqueioDialog = false
      }
    },

    validate() {
      this.enviado = true
      this.v$.motivo.$touch()

      if (this.v$.motivo.$invalid) {
        return false
      } else {
        return true
      }
    },

    salvar(isAtivo, isBloqueado, msg) {
      this.bloqueioMatricula.matricula = this.store.dados
      this.bloqueioMatriculaService
        .saveBloqueioMatricula(this.bloqueioMatricula)
        .then(() => {
          this.bloqueioMatricula = new BloqueioMatricula()
          this.store.dados.ativo = isAtivo
          this.store.dados.bloqueada = isBloqueado
          bus.$emit('carregarDadosServidor')
          this.carregaBloqueioMatriculas()
          this.exibeToast(msg)
          this.enviado = false
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Ocorreu um erro. Tente novamente!',
            life: 10000,
          })
        })
    },

    exibeToast(msg) {
      this.$toast.add({
        severity: 'success',
        summary: `${msg} realizado com sucesso!`,
        life: 10000,
      })
    },
  },
}
</script>
<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
